import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "slick-carousel/slick/slick-theme.css";
import './ProjectSection.scss';

const ProjectsSection = () => {
  const [githubProjects, setGithubProjects] = useState([]);

  useEffect(() => {
    // Fetch GitHub projects
    axios.get('https://api.github.com/users/DiggingNebula8/repos?per_page=100')
    .then(res => {
      const includedProjects = res.data.filter(project => 
        ['shader-art', 'Mathscapes', 'vis-psyche'].includes(project.name)
      );
      setGithubProjects(includedProjects);
    })
    .catch(err => console.log(err));
  }, []);

  return (
    <section className="projects-section">
      <h2>Projects</h2>
      <div className="github-projects">
        {githubProjects.map(project => (
          <div className="project" key={project.id}>
            <h3>{project.name}</h3>
            <p>{project.description}</p>
            <a href={project.html_url} target="_blank" rel="noopener noreferrer">View on Github</a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProjectsSection;
