import React from 'react';
import { Link } from 'react-router-dom'; 
import './LandingPage.scss';
import ProjectsSection from './LandingPageContent/ProjectsSection';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header className="hero">
        <h1 className="title">Altpsyche</h1>
        <p className="subtitle">A Creative Technical Artist's Portfolio</p>
      </header>
      <main className="content">
      <div className="excerpt-sections">
        <div className="excerpt">
          <h2>Video Games</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rutrum quis sem sed pharetra.</p>
          <Link to="/video-games" className="read-more">Read More</Link>
        </div>
        <div className="excerpt">
          <h2>Game Development</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rutrum quis sem sed pharetra.</p>
          <Link to="/game-development" className="read-more">Read More</Link>
        </div>
        <div className="excerpt">
          <h2>Cultural Nexus</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas rutrum quis sem sed pharetra.</p>
          <Link to="/cultural-nexus" className="read-more">Read More</Link>
        </div>
      </div>
      </main>
      <ProjectsSection  />
    </div>
  );
};

export default LandingPage;
